// File: mvsync-frontend/mvsync-frontend/src/ArtifactWindow.js
import React, { useState, useEffect } from 'react';
import { Eye, FileCode, Link, X } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const DEPLOY_URL = 'https://api.stephanyaz.com:5001/deploy';

function ArtifactWindow({ artifactContent, onClose, isVisible }) {
  const [artifactView, setArtifactView] = useState('preview');
  const [deployedUrl, setDeployedUrl] = useState(null);
  const [deploymentStatus, setDeploymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (artifactContent) {
      setIsLoading(true);
      // Use a shorter timeout for demo purposes
      setTimeout(() => setIsLoading(false), 200);
    }
  }, [artifactContent]);

  const deployArtifact = async () => {
    if (!artifactContent) return;

    setDeploymentStatus('deploying');
    setArtifactView('link');

    try {
      const response = await fetch(DEPLOY_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ html: artifactContent }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setDeployedUrl(data.url);
      setDeploymentStatus('success');
    } catch (error) {
      console.error('Error deploying artifact:', error);
      setDeploymentStatus('error');
    }
  };

  const handleLinkClick = () => {
    if (deploymentStatus !== 'success') {
      deployArtifact();
    }
    setArtifactView('link');
  };

  const formatUrl = (url) => {
    return url.replace(/^https?:\/\//, '');
  };

  const renderArtifact = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    switch (artifactView) {
      case 'preview':
        return (
          <div className="w-full h-full bg-white overflow-auto">
            <iframe
              srcDoc={artifactContent}
              title="HTML Preview"
              className="w-full h-full border-none"
              sandbox="allow-scripts"
            />
          </div>
        );
      case 'code':
        return (
          <SyntaxHighlighter 
            language="html"
            style={tomorrow}
            className="rounded h-full"
          >
            {artifactContent}
          </SyntaxHighlighter>
        );
      case 'link':
        return (
          <div className="flex items-center justify-center h-full">
            {deploymentStatus === 'deploying' ? (
              <LoadingSpinner />
            ) : deploymentStatus === 'success' ? (
              <p className="text-2xl font-bold text-black">{formatUrl(deployedUrl)}</p>
            ) : deploymentStatus === 'error' ? (
              <p className="text-xl text-red-500">Failed to deploy. Please try again.</p>
            ) : (
              <button
                onClick={deployArtifact}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Deploy Artifact
              </button>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`w-1/2 bg-white border-l border-gray-200 flex flex-col ${isVisible ? '' : 'hidden'}`}>
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center">
          HTML Artifact
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setArtifactView('preview')}
            className={`p-2 rounded-full ${artifactView === 'preview' ? 'bg-blue-100 text-blue-500' : 'text-gray-500 hover:bg-gray-100'}`}
          >
            <Eye size={20} />
          </button>
          <button
            onClick={() => setArtifactView('code')}
            className={`p-2 rounded-full ${artifactView === 'code' ? 'bg-blue-100 text-blue-500' : 'text-gray-500 hover:bg-gray-100'}`}
          >
            <FileCode size={20} />
          </button>
          <button
            onClick={handleLinkClick}
            className={`p-2 rounded-full ${artifactView === 'link' ? 'bg-blue-100 text-blue-500' : 'text-gray-500 hover:bg-gray-100'}`}
          >
            <Link size={20} />
          </button>
          <button
            onClick={onClose}
            className="p-2 rounded-full text-gray-500 hover:bg-gray-100"
          >
            <X size={20} />
          </button>
        </div>
      </div>
      <div className="flex-1 p-4 bg-gray-50 overflow-auto">
        {renderArtifact()}
      </div>
    </div>
  );
}

function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
}

export default ArtifactWindow;