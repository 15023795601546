// File: mvsync-frontend/mvsync-frontend/src/HomePage.js
import React, { useState } from 'react';
import QuickStart from './QuickStart';
import RecentChats from './RecentChats';

function HomePage({ startChat, setInputValue }) {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      startChat(input);
    }
  };

  const handleQuickActionSelect = (text) => {
    setInput(text);
    setInputValue(text);
  };

  return (
    <div className="flex-1 p-8 overflow-y-auto">
      <div className="max-w-2xl mx-auto">
        <form onSubmit={handleSubmit} className="mb-8">
          <div className="flex">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask MVSync anything..."
              className="flex-1 p-3 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-r-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send
            </button>
          </div>
        </form>

        <QuickStart onQuickActionSelect={handleQuickActionSelect} />
        <RecentChats />
      </div>
    </div>
  );
}

export default HomePage;