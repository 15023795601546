import React, { useState, useEffect, useRef } from 'react';
import MessageList from './MessageList';
import LoadingIndicator from './LoadingIndicator';
import FollowUpSuggestions from './FollowUpSuggestions';
import ArtifactWindow from './ArtifactWindow';
import MessageSender from './MessageSender';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.stephanyaz.com:5001';

function ChatInterface({ setArtifact, toggleArtifact, initialMessage, inputValue, setInputValue, conversationId, setConversationId }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStreamedMessage, setCurrentStreamedMessage] = useState(null);
  const [followUpSuggestions, setFollowUpSuggestions] = useState([]);
  const [artifactContent, setArtifactContent] = useState(null);
  const [isArtifactVisible, setIsArtifactVisible] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const initialMessageProcessed = useRef(false);

  useEffect(() => {
    if (initialMessage && !initialMessageProcessed.current) {
      handleSendMessage(initialMessage);
      initialMessageProcessed.current = true;
    }
  }, [initialMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentStreamedMessage]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === 'assistant') {
        fetchFollowUpSuggestions(lastMessage.content);
      }
    }
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeSearchTags = (text) => {
    return text.replace(/<search>.*?<\/search>/g, '');
  };

  const filterAndCombineMessages = (messages) => {
    return messages.map(message => {
      const filteredContent = message.content
        .filter(item => item.type !== 'search')
        .map(item => item.text)
        .join(' ');

      return { ...message, content: [{ type: 'text', text: filteredContent }] };
    });
  };

  const fetchFollowUpSuggestions = async (lastResponse) => {
    try {
      const filteredLastResponse = lastResponse
        .filter(item => item.type === 'text')
        .map(item => item.text)
        .join(' ');

      const response = await fetch(`${API_URL}/api/generate_follow_up`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ last_response: filteredLastResponse }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setFollowUpSuggestions(data.questions || []);
    } catch (error) {
      console.error('Error fetching follow-up suggestions:', error);
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim() || !conversationId) return;

    setIsLoading(true);
    const newUserMessage = { role: 'user', content: [{ type: 'text', text: message }] };
    setMessages(prev => [...prev, newUserMessage]);

    try {
      const filteredMessages = filterAndCombineMessages(messages);

      const response = await fetch(`${API_URL}/api/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          message, 
          history: filteredMessages, 
          conversation_id: conversationId 
        }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let accumulatedContent = [];
      let currentTextChunk = '';
      let isSearching = false;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const events = chunk.split('\n\n').filter(Boolean);

        for (const event of events) {
          if (event.startsWith('data: ')) {
            const data = JSON.parse(event.slice(6));

            switch (data.type) {
              case 'message_start':
                setCurrentStreamedMessage({ role: 'assistant', content: [] });
                break;
              case 'content':
                if (isSearching) {
                  isSearching = false;
                  accumulatedContent[accumulatedContent.length - 1].isActive = false;
                }
                currentTextChunk += removeSearchTags(data.text);
                setCurrentStreamedMessage(prev => ({
                  ...prev,
                  content: [...accumulatedContent, { type: 'text', text: currentTextChunk }]
                }));
                break;
              case 'search':
                if (currentTextChunk) {
                  accumulatedContent.push({ type: 'text', text: currentTextChunk });
                  currentTextChunk = '';
                }
                isSearching = true;
                accumulatedContent.push({ type: 'search', query: data.query, isActive: true });
                setCurrentStreamedMessage(prev => ({
                  ...prev,
                  content: [...accumulatedContent]
                }));
                break;
              case 'artifact':
                if (currentTextChunk) {
                  accumulatedContent.push({ type: 'text', text: currentTextChunk });
                  currentTextChunk = '';
                }
                accumulatedContent.push({ type: 'artifact', name: data.name, content: data.content });
                setCurrentStreamedMessage(prev => ({
                  ...prev,
                  content: [...accumulatedContent]
                }));
                break;
              case 'error':
                console.error('Error from backend:', data.message);
                setMessages(prev => [...prev, { role: 'assistant', content: [{ type: 'text', text: 'Sorry, an error occurred.' }] }]);
                break;
              case 'message_stop':
                if (currentTextChunk) {
                  accumulatedContent.push({ type: 'text', text: removeSearchTags(currentTextChunk) });
                }
                setMessages(prev => [...prev, { role: 'assistant', content: accumulatedContent }]);
                setCurrentStreamedMessage(null);
                break;
              default:
                console.warn('Unknown event type:', data.type);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, { role: 'assistant', content: [{ type: 'text', text: 'Sorry, there was an error processing your request.' }] }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      handleSendMessage(inputValue);
      setInputValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputValue(suggestion);
    inputRef.current.focus();
  };

  const openArtifactWindow = (content) => {
    setArtifactContent(content);
    setIsArtifactVisible(true);
  };

  const closeArtifactWindow = () => {
    setIsArtifactVisible(false);
  };

  return (
    <div className="flex-1 flex overflow-hidden bg-white">
      <div className="flex-1 flex flex-col">
        <MessageList
          messages={messages}
          currentStreamedMessage={currentStreamedMessage}
          setArtifact={setArtifact}
          toggleArtifact={toggleArtifact}
          openArtifactWindow={openArtifactWindow}
          messagesEndRef={messagesEndRef}
        />
        <div className="border-t border-gray-200 p-4">
          <FollowUpSuggestions
            suggestions={followUpSuggestions}
            onSuggestionSelect={handleSuggestionSelect}
          />
          <MessageSender
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSubmit={handleSubmit}
            handleKeyDown={handleKeyDown}
            isLoading={isLoading}
            inputRef={inputRef}
          />
        </div>
        {isLoading && <LoadingIndicator />}
      </div>
      <ArtifactWindow
        artifactContent={artifactContent}
        isVisible={isArtifactVisible}
        onClose={closeArtifactWindow}
      />
    </div>
  );
}

export default ChatInterface;