// File: mvsync-frontend/mvsync-frontend/src/MessageContent.js
import React from 'react';
import { motion } from 'framer-motion';
import ArtifactButton from './ArtifactButton';
import MarkdownRender from './MarkdownRender';

function MessageContent({ message, openArtifactWindow }) {
  const removeSearchTags = (text) => {
    return text.replace(/<search>.*?<\/search>/g, '');
  };

  const renderContent = () => {
    return message.content.map((item, index) => {
      switch (item.type) {
        case 'text':
          const artifactRegex = /<artifact.*?name="(.*?)".*?>([\s\S]*?)<\/artifact>/gs;
          let match;
          let lastIndex = 0;
          const elements = [];

          while ((match = artifactRegex.exec(item.text)) !== null) {
            const [fullMatch, name, content] = match;
            const textBeforeArtifact = item.text.slice(lastIndex, match.index);
            
            if (textBeforeArtifact) {
              elements.push(
                <MarkdownRender key={`text-${index}-${lastIndex}`} text={removeSearchTags(textBeforeArtifact)} />
              );
            }

            elements.push(
              <ArtifactButton
                key={`artifact-${index}-${match.index}`}
                name={name}
                onClick={() => openArtifactWindow(content)}
              />
            );

            lastIndex = match.index + fullMatch.length;
          }

          const textAfterArtifacts = item.text.slice(lastIndex);
          if (textAfterArtifacts) {
            elements.push(
              <MarkdownRender key={`text-${index}-${lastIndex}`} text={removeSearchTags(textAfterArtifacts)} />
            );
          }

          return elements;
        case 'search':
          return (
            <motion.div
              key={index}
              className="inline-flex items-center my-2 px-3 py-1 bg-blue-50 border border-blue-200 text-blue-700 rounded-full text-sm"
              initial={{ opacity: 0.5 }}
              animate={item.isActive ? { opacity: [0.5, 1] } : { opacity: 1 }}
              transition={item.isActive ? { repeat: Infinity, duration: 1 } : { duration: 0.3 }}
            >
              <span className="mr-1">🔍</span>
              Searching: {item.query}
            </motion.div>
          );
        case 'artifact':
          return (
            <ArtifactButton
              key={index}
              name={item.name}
              onClick={() => openArtifactWindow(item.content)}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
}

export default MessageContent;