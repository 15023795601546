import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

function FollowUpSuggestions({ suggestions, onSuggestionSelect }) {
  const [isExpanded, setIsExpanded] = useState(true);

  if (!suggestions || suggestions.length === 0) {
    return null;
  }

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-sm font-semibold text-gray-700">Suggested follow-ups</h3>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-500 hover:text-gray-700"
        >
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </button>
      </div>
      {isExpanded && (
        <div className="grid grid-cols-2 gap-2">
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              onClick={() => onSuggestionSelect(suggestion)}
              className="p-2 bg-gray-100 text-sm text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200 text-left overflow-hidden"
              style={{ 
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minHeight: '4.5rem'
              }}
            >
              {suggestion}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default FollowUpSuggestions;