// File: mvsync-frontend/mvsync-frontend/src/MessageList.js
import React from 'react';
import Message from './Message';

function MessageList({ messages, currentStreamedMessage, setArtifact, toggleArtifact, openArtifactWindow, messagesEndRef }) {
  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      {messages.map((message, index) => (
        <Message 
          key={index} 
          message={message} 
          setArtifact={setArtifact}
          toggleArtifact={toggleArtifact}
          openArtifactWindow={openArtifactWindow}
        />
      ))}
      {currentStreamedMessage && (
        <Message 
          message={currentStreamedMessage} 
          setArtifact={setArtifact}
          toggleArtifact={toggleArtifact}
          isStreaming={true}
          openArtifactWindow={openArtifactWindow}
        />
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default MessageList;