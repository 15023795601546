import React, { useState } from 'react';
import ChatInterface from './ChatInterface';
import ArtifactWindow from './ArtifactWindow';
import HomePage from './HomePage';
import logo from './logo.png';
import './App.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.stephanyaz.com:5001';

function App() {
  const [artifact, setArtifact] = useState(null);
  const [isArtifactVisible, setIsArtifactVisible] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [initialMessage, setInitialMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [conversationId, setConversationId] = useState(null);

  const toggleArtifact = () => {
    setIsArtifactVisible(!isArtifactVisible);
  };

  const startChat = async (message) => {
    try {
      const response = await fetch(`${API_URL}/api/start_conversation`, {
        method: 'POST',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setConversationId(data.conversation_id);
      setInitialMessage(message);
      setIsHomePage(false);
    } catch (error) {
      console.error('Error starting new conversation:', error);
    }
  };

  const resetChat = () => {
    setArtifact(null);
    setIsArtifactVisible(false);
    setIsHomePage(true);
    setInitialMessage('');
    setInputValue('');
    setConversationId(null);
  };

  return (
    <div className="flex flex-col h-screen bg-warm-beige font-sans">
      <header className="bg-white shadow-sm p-4 flex justify-between items-center">
        <img src={logo} alt="MVSync Logo" className="h-8 w-auto cursor-pointer" onClick={resetChat} />
      </header>
      <main className="flex-1 flex overflow-hidden">
        {isHomePage ? (
          <HomePage startChat={startChat} setInputValue={setInputValue} />
        ) : (
          <ChatInterface 
            setArtifact={setArtifact} 
            toggleArtifact={toggleArtifact}
            initialMessage={initialMessage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            conversationId={conversationId}
            setConversationId={setConversationId}
          />
        )}
        <ArtifactWindow 
          artifact={artifact} 
          onClose={() => setIsArtifactVisible(false)}
          isVisible={isArtifactVisible}
        />
      </main>
    </div>
  );
}

export default App;