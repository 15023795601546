// File: mvsync-frontend/mvsync-frontend/src/Message.js
import React from 'react';
import MessageContent from './MessageContent';

function Message({ message, setArtifact, toggleArtifact, isStreaming, openArtifactWindow }) {
  return (
    <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`max-w-3xl rounded-lg p-4 ${
        message.role === 'user' 
          ? 'bg-blue-50 text-gray-800' 
          : 'bg-white border border-gray-200 shadow-sm'
      }`}>
        <div className="flex items-center mb-2">
          {message.role === 'user' ? (
            <div className="bg-blue-200 text-blue-700 rounded-full w-8 h-8 flex items-center justify-center mr-2 font-semibold">U</div>
          ) : (
            <div className="bg-gray-200 text-gray-700 rounded-full w-8 h-8 flex items-center justify-center mr-2 font-semibold">M</div>
          )}
          <span className="font-semibold">{message.role === 'user' ? 'You' : 'MVSync'}</span>
        </div>
        <div className="prose prose-sm max-w-none">
          <MessageContent
            message={message}
            openArtifactWindow={openArtifactWindow}
          />
          {isStreaming && (
            <span className="inline-block animate-pulse">▋</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Message;