// File: mvsync-frontend/mvsync-frontend/src/ArtifactButton.js
import React from 'react';

function ArtifactButton({ name, onClick }) {
  return (
    <button
      onClick={onClick}
      className="inline-block bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm mr-2 mb-2 hover:bg-blue-200 transition-colors"
    >
      {name}
    </button>
  );
}

export default ArtifactButton;