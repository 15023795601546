// File: mvsync-frontend/mvsync-frontend/src/MessageSender.js
import React from 'react';

function MessageSender({ inputValue, setInputValue, handleSubmit, handleKeyDown, isLoading, inputRef }) {
  return (
    <form onSubmit={handleSubmit} className="flex items-end">
      <textarea
        ref={inputRef}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Message MVSync..."
        className="flex-1 p-2 border border-gray-300 rounded-lg resize-none overflow-y-auto focus:outline-none focus:ring-2 focus:ring-indigo-500"
        style={{ minHeight: '44px', maxHeight: '200px' }}
      />
      <button
        type="submit"
        disabled={isLoading}
        className="ml-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        Send
      </button>
    </form>
  );
}

export default MessageSender;
