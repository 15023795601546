// File: mvsync-frontend/mvsync-frontend/src/RecentChats.js
import React from 'react';

function RecentChats() {
  const placeholderChats = [
    { id: 1, title: "Coming Soon", date: "Future Feature", icon: "🔜" },
    { id: 2, title: "Coming Soon", date: "Future Feature", icon: "🔜" },
    { id: 3, title: "Coming Soon", date: "Future Feature", icon: "🔜" },
  ];

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Recent Chats</h2>
      <div className="space-y-3">
        {placeholderChats.map((chat) => (
          <div
            key={chat.id}
            className="w-full flex items-center p-3 bg-white rounded-lg shadow-sm text-left border border-gray-200 cursor-not-allowed opacity-50"
          >
            <span className="text-xl mr-3">{chat.icon}</span>
            <div className="flex-1">
              <div className="font-medium text-sm">{chat.title}</div>
              <div className="text-xs text-gray-500">{chat.date}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentChats;