// File: mvsync-frontend/mvsync-frontend/src/MarkdownRender.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';

function MarkdownRender({ text }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              style={tomorrow}
              language={match[1]}
              PreTag="div"
              className="rounded-md my-2"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className="bg-gray-100 rounded px-1 py-0.5 text-sm" {...props}>
              {children}
            </code>
          )
        },
        p: ({node, ...props}) => <p className="mb-4 whitespace-pre-wrap" {...props} />,
        ul: ({node, ...props}) => <ul className="list-disc pl-6 mb-4" {...props} />,
        ol: ({node, ...props}) => <ol className="list-decimal pl-6 mb-4" {...props} />,
        li: ({node, ...props}) => <li className="mb-1" {...props} />,
        h1: ({node, ...props}) => <h1 className="text-2xl font-bold mb-4 mt-6" {...props} />,
        h2: ({node, ...props}) => <h2 className="text-xl font-bold mb-3 mt-5" {...props} />,
        h3: ({node, ...props}) => <h3 className="text-lg font-bold mb-2 mt-4" {...props} />,
        blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-gray-300 pl-4 py-2 mb-4 italic" {...props} />,
        a: ({node, ...props}) => <a className="text-blue-500 hover:underline" {...props} />,
        table: ({node, ...props}) => <table className="border-collapse border border-gray-300 my-4 w-full" {...props} />,
        th: ({node, ...props}) => <th className="border border-gray-300 px-4 py-2 bg-gray-100 font-bold" {...props} />,
        td: ({node, ...props}) => <td className="border border-gray-300 px-4 py-2" {...props} />,
      }}
      className="text-sm leading-relaxed"
    >
      {text}
    </ReactMarkdown>
  );
}

export default MarkdownRender;
