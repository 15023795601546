import React from 'react';

function QuickStart({ onQuickActionSelect }) {
  const quickStarts = [
    { icon: "📝", text: "Make me a lesson plan to teach my students about ..." },
    { icon: "🔍", text: "How can I implement ... standard from the teacher competency library into my current lesson?" },
    { icon: "📊", text: "How might I help struggling students understand ... concept in my ... grade ... class?" },
    { icon: "💡", text: "Make me a nice looking visual simulation to show my students ... concept in action." },
  ];

  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold mb-4">How can MVSync help you today?</h2>
      <div className="grid grid-cols-2 gap-4">
        {quickStarts.map((prompt, index) => (
          <button
            key={index}
            onClick={() => onQuickActionSelect(prompt.text)}
            className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 text-left border border-gray-200"
          >
            <span className="text-xl mr-3">{prompt.icon}</span>
            <span className="text-sm font-medium">{prompt.text}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default QuickStart;