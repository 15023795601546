import React from 'react';

function LoadingIndicator() {
  return (
    <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2">
      <div className="bg-white rounded-full p-3 shadow-lg flex items-center space-x-2">
        <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"></div>
        <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
        <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
      </div>
    </div>
  );
}

export default LoadingIndicator;